import request from '../request'

export default {
  getAllIncendios: function () {
    return request.get('lista_incendios')
  },

  getIncendios: function (daysBack, codCCAA, codProv) {
    return request.get(`incendio/${daysBack}/${codCCAA}/${codProv}`)
  },

  getTiposIncendioEstado: function () {
    return request.get('tipo_incendio_estado')
  },
  getTiposIncendioNivel: function () {
    return request.get('tipo_incendio_nivel')
  },

  getTiposDeteccion: function () {
    return request.get('tipo_deteccion')
  },

  addIncendio: function (data) {
    return request.post('incendio', data)
  },
  addIncendioBbox: function (bbox) {
    return request.post('incendio', bbox)
  },
  editIncendio: function (data) {
    return request.put(`incendio_id/${data.ID_INCENDIO}`, data)
  },
  ocultarIncendio: function (data) {
    return request.put(`ocultar_incendio/${data.ID_INCENDIO}/${data.OCULTO}`)
  },
  eliminarIncendio: function (data) {
    return request.put(`eliminar_incendio/${data.ID_INCENDIO}`)
  },

  addNivelIncendio: function (data) {
    return request.post('incendio_nivel', data)
  },

  deleteNivelIncendio: function (data) {
    return request.delete('incendio_nivel', { data: data })
  },
  editNivelIncendio: function (data) {
    return request.put('incendio_nivel', data)
  },
  addEstadoIncendio: function (data) {
    return request.post('incendio_estado', data)
  },
  editEstadoIncendio: function (data) {
    return request.put('incendio_estado', data)
  },
  deleteEstadoIncendio: function (data) {
    return request.delete('incendio_estado', { data: data })
  },

  changeSectoresIncendio: function (data) {
    return request.put(`sector/${data.ID_INCENDIO}`, data)
  },

  addMediosIncendio: function (data) {
    return request.post('medio_sector', data)
  },
  editMedioIncendio: function (data) {
    return request.put(`medio_sector/${data.ID_MEDIO_SECTOR}`, data)
  },
  deleteMedioIncendio: function (data) {
    return request.delete(`medio_sector/${data.ID_MEDIO_SECTOR}`, { data: data })
  },

  editPlanificacionMedio: function (data) {
    return request.put(`planificacion_medio/${data.PLANIFICACION.ID_PLANIFICACION_MEDIO}`, data.PLANIFICACION)
  },

  addPlanOperaciones: function (data) {
    return request.post('plan_operaciones', data)
  },
  editPlanOperaciones: function (data) {
    return request.put(`plan_operaciones/${data.ID_PLAN_OPERACIONES}`, data)
  },
  deletePlanOperaciones: function (data) {
    return request.delete(`plan_operaciones/${data.ID_PLAN_OPERACIONES}`)
  },

  editAlineacionesCampbell: function (data) {
    return request.put(`alineaciones_campbell/${data.ID_ALINEACIONES_CAMPBELL}`, data)
  },

  addPerimetro: function (data) {
    return request.post('perimetro', data)
  },

  editPerimetro: function (data) {
    return request.put(`perimetro/${data.ID_PERIMETRO}`, data)
  },

  deletePerimetro: function (data) {
    return request.delete(`perimetro/${data.ID_PERIMETRO}`)
  },

  addImportedPerimetro: function (data) {
    return request.post('perimetro/addImportedPerimetro', data)
  }
}
