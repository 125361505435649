<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Track entre fechas</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-container fluid>
            <!-- Fin descanso -->
            <v-row align="center">
              <v-col sm="10">
                <v-tooltip bottom right>
                  <template #activator="{ attrs }">
                    <v-select
                      v-model="fechaInicioSelectedItem" v-bind="attrs" :items="itemsInicio"
                      label="Fecha de inicio" dense outlined prepend-icon="esri-icon-calendar" single-line autocomplete
                      hide-details
                    />
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col sm="10">
                <v-tooltip bottom right>
                  <template #activator="{ attrs }">
                    <v-select
                      v-model="fechaFinSelectedItem" v-bind="attrs" :items="itemsFin" label="Fecha de fin" dense
                      outlined prepend-icon="esri-icon-calendar" single-line autocomplete hide-details
                    />
                  </template>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" @click="aceptar" :disabled="invalid">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    idIncendio: String
  },

  data: () => ({
    itemsInicio: [],
    fechaInicioSelectedItem: null,
    itemsFin: [''],
    fechaFinSelectedItem: null,
    invalid: true,
    valoresTrack: []
  }),

  computed: {

  },

  watch: {
    fechaInicioSelectedItem (value) {
      switch (value) {
        case 'Aviso':
          this.valoresTrack[0] = 0
          this.itemsFin = ['Llegada', 'Salida', 'Llegada a la base']
          break
        case 'Llegada':
          this.valoresTrack[0] = 1

          this.itemsFin = ['Salida', 'Llegada a la base']
          break

        case 'Salida':
          this.valoresTrack[0] = 2

          this.itemsFin = ['Llegada a la base']
          break
      }
    },
    fechaFinSelectedItem (value) {
      switch (value) {
        case 'Llegada a la base':
          this.valoresTrack[1] = 3
          break
        case 'Llegada':
          this.valoresTrack[1] = 2
          break
        case 'Salida':
          this.valoresTrack[1] = 3
          break
      }
      this.invalid = false
    },
    idIncendio (nuevoIncendio) {
      if (nuevoIncendio !== null) {
        this.invalid = true
        this.itemsInicio = ['Aviso', 'Llegada', 'Salida']
        this.itemsFin = []
        this.fechaFinSelectedItem = null
        this.fechaInicioSelectedItem = null
        this.valoresTrack = []
      }
    }

  },

  methods: {
    cancelar () {
      this.$emit('cancelar')
    },

    aceptar () {
      this.$emit('aceptar', this.valoresTrack[0], this.valoresTrack[1])
    }
  }
}
</script>

<style scoped></style>
