import request from '../request'

export default {
  getImagenesSatelite: function (data) {
    return request.get(`satelite_imagen/${data.ID_INCENDIO}`)
  },
  getImagenSateliteURL: function (data) {
    return `${request.defaults.baseURL}/satelite_imagen/${data.API}/${data.ID_INCENDIO}/${data.ESTADO}/${data.NOMBRE_FICHERO}`
  },
  getImagenSatelite: function (data, destinationFolder) {
    return request.get(`satelite_imagen/${data.API}/${data.ID_INCENDIO}/${data.ESTADO}/${data.NOMBRE_FICHERO}/${destinationFolder}`)
  },
  postTest: function (data) {
    return request.post('satelite_imagen/', data)
  },
  testImagen: function (nombreImagen) {
    return request.post(`satelite_imagen/${nombreImagen}`)
  },
  getImagenBbox: function (data, bbox) {
    return request.get(`satelite_imagen/${data.ID_INCENDIO}/${data.LONGITUD}/${data.LATITUD}/${data.TIPO_IMAGEN_SATELITE}/${bbox}`)
  },
  getImagenBboxURL: function (data, bbox, fromDate, toDate, maxCloudCoverage, destinationFolder) {
    return `${request.defaults.baseURL}/satelite_imagen/${data.ID_INCENDIO}/${data.LONGITUD}/${data.LATITUD}/${data.TIPO_IMAGEN_SATELITE}/${bbox}/${fromDate}/${toDate}/${maxCloudCoverage}/${destinationFolder}`
  },
  getWFSData: function (bbox, fromDate, toDate, maxCloudCoverage, priority) {
    return request.get(`satelite_imagen/${bbox}/${fromDate}/${toDate}/${maxCloudCoverage}/${priority}`)
  }
}
